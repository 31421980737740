/**
    This file is autogenerated using the command `yarn app-store:build --watch`.
    Don't modify this file manually.
**/
import hubspot_config_json from "./hubspot/config.json";
import basic_config_json from "./templates/basic/config.json";
import general_app_settings_config_json from "./templates/general-app-settings/config.json";
import link_as_an_app_config_json from "./templates/link-as-an-app/config.json";

export const appStoreMetadata = {
  hubspot: hubspot_config_json,
  basic: basic_config_json,
  "general-app-settings": general_app_settings_config_json,
  "link-as-an-app": link_as_an_app_config_json,
};
