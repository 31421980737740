export const getInitials = (name: string | null) => {
  if (!name) {
    return "";
  }

  let full_name = name.split(" ");
  let initials = full_name[0][0];
  if (name) {
    if (full_name.length >= 2 && full_name[1]) {
      initials += full_name[1][0];
    }
  }

  return initials.toUpperCase();
};