import { getInitials } from "@billy/lib";
import { trpc } from "@billy/trpc/react";
import {
  Avatar,
  Button,
  Dropdown,
  DropdownItem,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuPortal,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@billy/ui";
import { FiPlusCircle } from "@billy/ui/components/icon";
import { ChevronsUpDown } from "lucide-react";
import { useSession } from "next-auth/react";
import { useState } from "react";

export function OrganizationSwitcher() {
  const { data: session } = useSession();
  const [menuOpen, setMenuOpen] = useState(false);

  const switchOrganizationMutation =
    trpc.viewer.organizations.switch.useMutation({
      onError: async (err) => {
        console.error(err.message);
      },
      async onSettled() {
        window.location.href = "/dashboard";
      },
    });

  return (
    <>
      <Dropdown open={menuOpen}>
        <div className="relative">
          <DropdownMenuTrigger
            asChild
            onClick={() => setMenuOpen((menuOpen) => !menuOpen)}
          >
            <Button
              className="w-full focus:ring-0 text-gray-600 border px-2"
              color="minimal"
              StartIcon={(props) => (
                <Avatar
                  size="sm"
                  alt={session?.currentOrganization?.name || ''}
                  initials={getInitials(session?.currentOrganization?.name)}
                  className="mr-2 border-gray-200 bg-gray-200"
                />
              )}
            >
              {session?.currentOrganization?.name}
              <ChevronsUpDown className="ml-auto h-4 w-4 shrink-0 opacity-50" />
            </Button>
          </DropdownMenuTrigger>
        </div>
        <DropdownMenuPortal>
          <DropdownMenuContent
            align="start"
            className="overflow-hidden rounded-md"
            onInteractOutside={() => {
              setMenuOpen(false);
            }}
          >
            {session?.organizations?.map((organization: any) => (
              <DropdownMenuItem key={organization.id}>
                <DropdownItem
                  type="button"
                  onClick={() =>
                    switchOrganizationMutation.mutate({
                      id: organization.id,
                    })
                  }
                  StartIcon={(props) => (
                    <Avatar
                      size="sm"
                      alt={organization.name}
                      initials={getInitials(organization.name)}
                    />
                  )}
                >
                  {organization.name}
                </DropdownItem>
              </DropdownMenuItem>
            ))}
            <DropdownMenuSeparator className="-mx-1" />
            <DropdownItem
              type="button"
              StartIcon={(props) => (
                <FiPlusCircle
                  className="h-4 w-4 flex-shrink-0 text-gray-500 [&[aria-current='page']]:text-inherit"
                  aria-hidden="true"
                />
              )}
              href="/organizations/new"
            >
              New organization
            </DropdownItem>
          </DropdownMenuContent>
        </DropdownMenuPortal>
      </Dropdown>
    </>
  );
}
