// export const WEBAPP_URL = process.env.NEXT_PUBLIC_WEBAPP_URL;
export const WEBAPP_URL =
  process.env.NEXT_PUBLIC_WEBAPP_URL || "http://localhost:3000";

export const GOOGLE_CLIENT_ID = process.env.GOOGLE_CLIENT_ID;
export const GOOGLE_CLIENT_SECRET = process.env.GOOGLE_CLIENT_SECRET;
export const IS_GOOGLE_LOGIN_ENABLED = process.env.IS_GOOGLE_LOGIN_ENABLED;

export const WEBSITE_URL =
  process.env.NEXT_PUBLIC_WEBSITE_URL || "https://cal.com";

// This is the URL from which all Cal Links and their assets are served.
// Use website URL to make links shorter(cal.com and not app.cal.com)
// As website isn't setup for preview environments, use the webapp url instead
export const BILLY_URL = new URL(WEBAPP_URL).hostname.endsWith(".vercel.app")
  ? WEBAPP_URL
  : WEBSITE_URL;

export const APP_NAME = process.env.NEXT_PUBLIC_APP_NAME || "billycpq";
export const COMPANY_NAME = process.env.NEXT_PUBLIC_COMPANY_NAME || "billycpq";
export const SUPPORT_MAIL_ADDRESS = process.env.NEXT_PUBLIC_SUPPORT_MAIL_ADDRESS || "billy.cpq@gmail.com";

export const LOGO = "/logo.svg";