/**
    This file is autogenerated using the command `yarn app-store:build --watch`.
    Don't modify this file manually.
**/
import dynamic from "next/dynamic";

export const InstallAppButtonMap = {
  hubspot: dynamic(() => import("./hubspot/components/InstallAppButton")),
};
export const AppSettingsComponentsMap = {
  "general-app-settings": dynamic(() =>
    import("./templates/general-app-settings/components/AppSettingsInterface")
  ),
};
export const EventTypeAddonMap = {};
